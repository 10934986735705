import sass from "./Button.module.scss";
import { AnchorHTMLAttributes, FC, HTMLAttributes, MouseEventHandler, ReactNode } from "react";

type VariantButton = "accent" | "secondary";

type ButtonProps = {
  text: string,
  href?: string,
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"],
  rel?: HTMLAttributes<HTMLAnchorElement>["rel"],
  onClick?: MouseEventHandler<HTMLButtonElement>,
  icon?: ReactNode,
  variant: VariantButton,
}

export const Button: FC<ButtonProps> = ({
  text,
  href,
  onClick,
  target,
  rel,
  icon: Icon,
  variant,
}) => {

  const getStyles = (buttonVariant: VariantButton) => {
    switch (buttonVariant) {
      case "accent":
        return sass.buttonAccent;
      case "secondary":
        return sass.buttonSecondary;
      default:
        return sass.buttonAccent;
    }
  }

  return (
    href ? (
      <a className={getStyles(variant)} rel={rel ? rel : ""} target={target ? target : "_self"} href={href}>
        {Icon ? Icon : null}
        <span>{text}</span>
      </a>
    ) : (
      <button className={getStyles(variant)} onClick={onClick} type="button">
        {Icon ? Icon : null}
        <span>{text}</span>
      </button>
    )
  )
}
