import * as Yup from 'yup';

export const validationSchema = (options: string[]) => {
  return Yup.object().shape({
    cn: Yup
      .string()
      .required()
      .test('no-X', 'Введіт поле до кінця', (value) => {
        return new Promise(resolve => {
          resolve(!value || !value.includes('X'));
        });
      })
      .when("checked", ([checked], schema) => {
        return checked && checked.includes("isNotCnNumber")
          ? schema.notRequired()
          : schema
            .test('no-X', 'Введіт поле до кінця', (value) => {
              return new Promise(resolve => {
                resolve(!value || !value.includes('X'));
              });
            });
      }),
    name: Yup
      .string()
      .min(4)
      .required("Поле обовʼязкове"),
    phone: Yup
      .string()
      .test('no-X', 'Введіт поле до кінця', (value) => {
        return new Promise(resolve => {
          resolve(!value || !value.includes('X'));
        });
      })
      .required("Поле обовʼязкове"),
    checked: Yup
      .array()
      .of(Yup.string())
      .required("Поле обовʼязкове")
      .test('isAgreePersonalData', 'Поле обовʼязкове', (value) => {
        return new Promise(resolve => {
          resolve(value && value.includes("isAgreePersonalData"));
        });
      }),
    searchLocation: Yup
      .string()
      .when("checked", ([checked], schema) => {
        return checked && checked.includes("isNotCnNumber")
          ? schema
            .required("searchLocation is req")
            .oneOf(options, "values is from options")
          : schema;
      })
  });
}
