import { FC, useState } from "react";
import { BuyToSellSection } from "./sections";
import { PassportPhotoContext } from "../../hooks";

export const MainPage: FC = () => {
  const [passport, setPassport] = useState<File[]>([]);

  return (
    <PassportPhotoContext.Provider value={{
      passport,
      setPassport,
    }}>
      <BuyToSellSection />
    </PassportPhotoContext.Provider>
  )
}
