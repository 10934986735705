import sass from "./RequestSuccessScreen.module.scss";
import { FC } from "react";
import { useTranslation } from "../../../../../../hooks";
import { Description, RequestTitle } from "../index";
import { Button } from "../../../../../../components";

export const RequestSuccessScreen: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={sass.requestSuccessScreen}>
      <RequestTitle title={t("buyToSellPage.requestHasBeen")} />
      <Description text={t("buyToSellPage.trackStatus")} />
      <Button
        text={t("goToPersonalAccount")}
        variant="accent"
      />
    </div>
  )
}
