import { FC } from "react";

type SecurityPersonalDataIconProps = {
  width?: number,
  height?: number,
}

export const SecurityPersonalDataIcon: FC<SecurityPersonalDataIconProps> = ({ width, height }) => {
  return (
    <svg
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 32}
      height={height ? height : 32}
    ><path d="M26.2 21.37c-.858 2.33-2.156 4.357-3.859 6.023-1.938 1.896-4.476 3.403-7.543 4.477a2.348 2.348 0 01-.733.13h-.027a2.338 2.338 0 01-.76-.129c-3.07-1.073-5.611-2.579-7.551-4.475-1.703-1.666-3.002-3.691-3.859-6.022C.31 17.136.398 12.467.47 8.714l.002-.057c.014-.31.023-.635.028-.995A3.389 3.389 0 013.687 4.34C7.36 4.135 10.2 2.935 12.627.564l.022-.02a2.043 2.043 0 012.778 0l.02.02c2.428 2.37 5.27 3.57 8.942 3.776a3.389 3.389 0 013.187 3.322c.005.362.015.687.03.995v.024c.071 3.76.16 8.438-1.405 12.688z" fill="#529ED0"></path><path d="M26.2 21.37c-.858 2.33-2.156 4.357-3.859 6.023-1.938 1.896-4.476 3.403-7.543 4.477a2.377 2.377 0 01-.733.13V0c.488.006.973.188 1.362.545l.02.02c2.428 2.37 5.27 3.57 8.942 3.775a3.389 3.389 0 013.188 3.322c.005.362.014.687.028.995l.001.024c.071 3.76.16 8.438-1.405 12.688z" fill="#3867B1"></path><path d="M21.997 16c0 4.388-3.556 7.96-7.932 7.975h-.028c-4.39 0-7.96-3.578-7.96-7.975 0-4.398 3.57-7.975 7.96-7.975h.028c4.376.015 7.932 3.587 7.932 7.975z" fill="#fff"></path><path d="M21.998 16c0 4.388-3.556 7.96-7.933 7.975V8.025c4.377.015 7.933 3.587 7.933 7.975z" fill="#fff"></path><path d="M17.651 14.852l-3.585 3.592-.775.776a.933.933 0 01-1.325 0L10.3 17.55a.94.94 0 010-1.327.936.936 0 011.325 0l1.004 1.005 3.697-3.704a.935.935 0 011.325 0 .94.94 0 010 1.328z" fill="#3F4E5D"></path><path d="M17.65 14.852l-3.585 3.592v-2.656l2.26-2.264a.935.935 0 011.326 0 .94.94 0 010 1.328z" fill="#3F4E5D"></path></svg>
  );
};
