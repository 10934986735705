import sass from "./TextInput.module.scss";
import { FC, HTMLAttributes, InputHTMLAttributes } from "react";
import { FormikValues } from "formik";
// @ts-ignore
import InputMask from "react-input-mask";

type TextInputProps = {
  name: string,
  formik: FormikValues,
  label?: string,
  type?: InputHTMLAttributes<HTMLInputElement>["type"],
  placeholder?: string,
  mask?: string,
  maskChar?: string,
  disabled?: boolean,
  min?: string,
  labelStyles?: HTMLAttributes<HTMLLabelElement>["style"],
}

export const TextInput: FC<TextInputProps> = ({
  formik,
  label,
  placeholder,
  type = "text",
  name,
  mask,
  maskChar,
  disabled,
  min,
  labelStyles,
}) => {
  const isError = formik.touched[name] && formik.errors[name];

  return (
    <label
      style={labelStyles}
      className={sass.label}
      htmlFor={name}
    >
      <span className={sass.text}>{label}</span>
      <InputMask
        maskChar={maskChar ? maskChar : null}
        mask={mask ? mask : null}
        name={name}
        id={name}
        type={type}
        className={isError ? sass.inputError : sass.input}
        placeholder={placeholder ? placeholder : ""}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        disabled={disabled ? disabled : false}
        min={min}
      />
      {/* Uncomment if needed show error text */}
      {/* {
        isError && (
          <span style={{ color: "red" }}>{formik.errors[name]}</span>
        )
      } */}
    </label>
  );
};
