import sass from "./LoaderScreen.module.scss";
import { FC } from "react";

export const LoaderScreen: FC = () => {
  return (
    <div className={sass.screen}>
      <div className={sass.spinner}>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
        <div className={sass.spinnerBlade}></div>
      </div>
    </div>
  )
}
