import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  checked: Yup
    .array()
    .of(Yup.string())
    .required("Поле обовʼязкове")
    .test("agree", "Поле обовʼязкове", (value) => {
      return new Promise(resolve => {
        resolve(value && value.includes("agree"));
      });
    }),
  photo: Yup
    .array()
    .min(2, "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)")
    .required("Поле обовʼязкове")
    .test("fileFormat", "Формат файла должен быть .jpg, .jpeg, .png, .zip, .rar, .7zip, .doc", (value) => {
      if (!value) return true;

      const validFormats = ["jpg", "png", "jpeg", "zip", "rar", "7zip", "doc"];
      return value.every(file => {
        const fileName = file.name.toLowerCase();
        return validFormats.some(format => fileName.endsWith(`.${format}`));
      });
    })
    .test("FILE_SIZE", "Розмір кожного файлу повинен бути менше 5 МБ", (value) => {
      if (!value) return true;

      const maxSize = 5e6; // 5 MB
      return value.every(file => file.size <= maxSize);
    }),
});
