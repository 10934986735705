import { useEffect, useRef } from "react";

export const useDisabled = () => {
  const refBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (refBtn.current) {
      refBtn.current.disabled = true;
    }
  }, []);

  return refBtn;
}
