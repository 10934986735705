export const appLanguages = {
  en: "en",
  ua: "ua",
} as const;

export type AppLanguages = keyof typeof appLanguages;

export type Koatuu = {
  nameOfTheObject: string,
  secondLevel: string,
};

export type Option = {
  value: string,
  label: string,
  secondLevel?: string,
}

export type OptionWithId = { id: string } & Option;

export type PassBuyInfoParams = {
  cn?: string,
  name: string,
  phone: string,
  address?: {
    KOATUU: number,
  }
}

export type FetchedBuyInfo = {
  mid: string,
  id: number,
  cn: string,
  status: string,
  isRRPLandDataExist: boolean,
  isRRPLimitDataExist: boolean,
  priceSource: number,
  isLot: boolean,
  data: {
    price: number,
    price2: number,
    area: number,
    areaUM: string
  }
};

export type InfoForSell = {
  body: string,
  subject: string,
  to: string[],
}

export type CancelReason = "" | "insufficientAmount" | "notReady" | "other";

export type CanceledFormInitialValues = {
  cancelReason: CancelReason,
  checked: string[],
  enterYourPrice: string,
  remind: string,
  userReason: string,
}

export type InitialValuesFormManuallyData = {
  photo: File[],
  checked: string[],
}

export type InitialValuesFormLastStep = InitialValuesFormManuallyData & {
  typeOfDocument: string,
};

export type PassCanceledInfoParams = {
  data: {
    documents: any[],
    price: number,
    reason: string,
    result: string,
  },
  mid: string,
}

export type InfoParamsData = Pick<PassCanceledInfoParams["data"], "documents" | "price" | "reason" | "result">;
