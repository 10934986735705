import sass from "./FormLastStep.module.scss";
import { ChangeEvent, FC, useEffect } from "react";
import { useFormik } from "formik";
import { IoClose } from "react-icons/io5";
import { useAppDispatch, usePassportPhoto, useReduxState, useTranslation } from "../../../../../../hooks";
import { fetchTypesOfDocument, passCanceledInfo, setSwitchValue } from "../../../../../../redux";
import { Description, RequestTitle, SubmitButton } from "../index";
import { BackArrowIcon } from "../../../../../../icons";
import { Checkbox, SelectLocalities, TakePhotoInput } from "../../../../../../components";
import { validationSchema } from "./validationSchema";
import { AppLanguages, InfoParamsData, InitialValuesFormLastStep } from "../../../../../../d";

type FormLastStepProps = {
  backValue: number,
  forwardValue: number,
}

export const FormLastStep: FC<FormLastStepProps> = ({ backValue, forwardValue }) => {
  const { t, i18n } = useTranslation();
  const { buyToSell } = useReduxState();
  const { passport } = usePassportPhoto();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTypesOfDocument(i18n.language as AppLanguages));
  }, [dispatch, i18n.language]);

  const initialValues: InitialValuesFormLastStep = {
    typeOfDocument: "",
    photo: [],
    checked: ["agree"],
  }

  const onSubmit = async (values: InitialValuesFormLastStep) => {
    const data: InfoParamsData = {
      documents: [...passport, ...values.photo],
      price: buyToSell.fetchedBuyInfo!.data.price,
      reason: "",
      result: "",
    }

    const passInfoRes = await dispatch(passCanceledInfo({ data, mid: buyToSell.buy.mid }));

    if (passInfoRes.type !== "pass-canceled-info/fulfilled") return;

    dispatch(setSwitchValue(forwardValue));
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const handleChangePhoto = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files = target.files;

    if (files && files.length > 0) {
      formik.setFieldValue("photo", Array.from(files));
    }
  }

  const removeFile = (index: number) => {
    const updatedPhotos = [...formik.values.photo];
    updatedPhotos.splice(index, 1);
    formik.setFieldValue("photo", updatedPhotos);
  }

  const isDisabled = Object.values(formik.errors).length !== 0;

  return (
    <form
      className={sass.formLastStep}
      onSubmit={formik.handleSubmit}
    >
      <div className={sass.formLastStepTop}>
        <button
          type="button"
          onClick={() => dispatch(setSwitchValue(backValue))}
        >
          <BackArrowIcon />
        </button>
        <RequestTitle title={t("buyToSellPage.requestForLand")} />
      </div>
      <div className={sass.description}>
        <Description text={t("buyToSellPage.lastStep")} />
        <Description text={t("buyToSellPage.provideProof")} />
      </div>
      <div className={sass.selectTypeOfDoc}>
        <SelectLocalities
          text={t("typeOfDocument")}
          name="typeOfDocument"
          options={buyToSell.typesOfDocument}
          placeholder={t("selectDocumentType")}
          formik={formik}
        />
      </div>
      <div className={sass.inputFile}>
        <TakePhotoInput
          label={t("buyToSellPage.uploadYourDocument")}
          name="photo"
          formik={formik}
          onChange={handleChangePhoto}
          accept=".jpg, .jpeg, .png, .zip, .rar, .7zip, .doc"
        />
        {
          formik.values.photo.length > 0 &&
          (
            <ul className={sass.filesList}>
              {
                formik.values.photo.map((file, index) => (
                  <li key={file.name} className={sass.fileItem}>
                    <span className={sass.fileName}>{file.name}</span>
                    <button
                      className={sass.removeFileBtn}
                      onClick={() => removeFile(index)}
                      type="button"
                    >
                      <IoClose color="#b52828" size={25} />
                    </button>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
      <div className={sass.formLastStepBottom}>
        <div className={sass.formLastStepBottomInner}>
          <SubmitButton
            text={t("continue")}
            type="submit"
            disabled={isDisabled}
          />
          <Checkbox
            formik={formik}
            name="checked"
            value="agree"
            label={t("buyToSellPage.agreePersonalData")}
            labelStyle={{
              color: "#7e96ae",
            }}
          />
        </div>
      </div>
    </form>
  )
}
