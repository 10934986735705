import sass from "./UserReasonTextArea.module.scss";
import { FormikValues } from "formik";
import { FC } from "react";
import { useSwitch } from "../../../../../../hooks";

type UserReasonTextAreaProps = {
  formik: FormikValues,
  name: string,
  placeholder?: string,
}

export const UserReasonTextArea: FC<UserReasonTextAreaProps> = ({
  formik,
  name,
  placeholder,
}) => {
  const isStart = useSwitch();

  const isError = formik.errors[name] && formik.touched[name];

  return (
    <div className={isStart ? sass.bodyActive : sass.body}>
      <textarea
        className={isError ? sass.userReasonError : sass.userReason}
        name={name}
        placeholder={placeholder ? placeholder : ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      ></textarea>
    </div>
  )
}
