import { createContext, useContext, Dispatch, SetStateAction } from "react";

type PassportPhotoContextValue = {
  passport: File[];
  setPassport: Dispatch<SetStateAction<File[]>>;
};

export const PassportPhotoContext = createContext<PassportPhotoContextValue>({
  passport: [],
  setPassport: () => { },
});

export const usePassportPhoto = () => useContext(PassportPhotoContext);
