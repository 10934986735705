import * as Yup from 'yup';

const regexNums = /^[0-9]+$/;
const regexDate = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const validationSchema = Yup.object().shape({
  cancelReason: Yup
    .string()
    .required()
    .test("notEmpty", 'Поле не должно быть пустым', (value) => {
      return new Promise(resolve => {
        resolve(value.trim() !== "");
      });
    }),
  checked: Yup
    .array()
    .of(Yup.string())
    .required("Поле обовʼязкове")
    .test("agree", "Поле обовʼязкове", (value) => {
      return new Promise(resolve => {
        resolve(value && value.includes("agree"));
      });
    }),
  enterYourPrice: Yup
    .string()
    .when("cancelReason", ([checked], schema) => {
      return checked === "insufficientAmount"
        ? schema
          .required("Поле обовʼязкове")
          .matches(regexNums, "Поле повинно містити тільки цифри")
        : schema;
    }),
  remind: Yup
    .string()
    .when("cancelReason", ([checked], schema) => {
      return checked === "notReady"
        ? schema
          .required("Поле обовʼязкове")
          .matches(regexDate, "Введіть дату в форматі \"день-місяць-рік\"")
          .test("isFutureDate", "Дата не повинна бути в минулому часі", (value) => {
            const selectedDate = new Date(value);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return selectedDate >= currentDate;
          })
        : schema;
    }),
  userReason: Yup
    .string()
    .when("cancelReason", ([checked], schema) => {
      return checked === "other"
        ? schema
          .required("Поле обовʼязкове")
          .min(2)
        : schema;
    })
});
