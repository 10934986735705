import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import {
  AppLanguages,
  FetchedBuyInfo,
  InfoForSell,
  Option,
  PassBuyInfoParams,
  OptionWithId,
  Koatuu,
  PassCanceledInfoParams,
} from './../../d';

export const passBuyInfo = createAsyncThunk<any, PassBuyInfoParams>(
  "pass-buy-info",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post("/landpropstart", { channel: "site", ...body });
      return response.data;
    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);

export const fetchBuyInfo = createAsyncThunk<FetchedBuyInfo, { mid: string }>(
  "fetch-sell-info",
  async ({ mid }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<FetchedBuyInfo> = await axios.get(`/landpropget?mid=${mid}&channel=site`);
      return response.data;
    } catch (error) {
      console.warn(error);
      return rejectWithValue(error);
    }
  }
);

export const passSellInfo = createAsyncThunk<any, InfoForSell>(
  "pass-sell-info",
  async (body, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post("/sendmail", body);
      return response.data;
    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);

export const fetchKoatuu = createAsyncThunk<OptionWithId[]>(
  "fetch-koatuu",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch("/koatuu_new.json");
      const data: Koatuu[] = await response.json();
      return data.map(({ nameOfTheObject, secondLevel }, id) => ({ label: nameOfTheObject, value: nameOfTheObject, id: `index-${id}`, secondLevel, }));
    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);

export const fetchTypesOfDocument = createAsyncThunk<Option[], AppLanguages>(
  "fetch-types-of-document",
  async (language, { rejectWithValue }) => {
    try {

      switch (language) {
        case "en":
          const responseEn = await fetch("/types_of_documents_en.json");
          return await responseEn.json();
        case "ua":
          const responseUa = await fetch("/types_of_documents_ua.json");
          return await responseUa.json();
      }

    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);

export const passUserInfoForSell = createAsyncThunk(
  "pass-user-info-for-sell",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.post("/andpropcomplete", body);
      return response.data;
    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);

export const passCanceledInfo = createAsyncThunk<{}, PassCanceledInfoParams>(
  "pass-canceled-info",
  async ({ data, mid }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/landpropcomplete", { data, mid, channel: "site" });
      return response.data;
    } catch (error: any) {
      console.warn(error);
      toast.error(`${error.message}`);
      return rejectWithValue(error);
    }
  }
);
