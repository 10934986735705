import { configureStore, combineReducers } from "@reduxjs/toolkit";
import axios from "axios";

import buyToSellReducer from "./buyToSell/slice";

axios.defaults.baseURL = "https://api-mp.land-invest.com.ua/api";

const reducer = combineReducers({
    buyToSell: buyToSellReducer,
});

export const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
