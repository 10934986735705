import sass from "./Recall.module.scss";
import { FC } from "react";
import { useAppDispatch, useTranslation } from "../../../../../../hooks";
import { setSwitchValue } from "../../../../../../redux";
import { Description, RequestTitle } from "../index";
import { Button } from "../../../../../../components";

type RecallProps = {
  backValue: number,
}

export const Recall: FC<RecallProps> = ({ backValue }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={sass.recall}>
      <div className={sass.title}>
        <RequestTitle title={t("buyToSellPage.thank")} />
      </div>
      <Description text={t("buyToSellPage.yourData")} />
      <Button
        text={t("ok")}
        variant="accent"
        onClick={() => dispatch(setSwitchValue(backValue))}
      />
    </div>
  )
}
