import sass from "./FormsSwitcher.module.scss";
import { SwitcherButton } from "../SwitcherButton";
import { FC } from "react";
import { useTranslation } from "../../../../../../hooks";

export const FormsSwitcher: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={sass.formsSwitcher}>
      <SwitcherButton
        text={t("buyToSellPage.sell")}
        switchValue={0}
        activeValues={[0, 2, 3, 5, 6, 7, 8, 9]}
        style={{ borderTopLeftRadius: 24, borderBottomLeftRadius: 24, }}
      />
      <SwitcherButton
        text={t("buyToSellPage.buy")}
        switchValue={1}
        activeValues={[1, 4]}
        style={{ borderTopRightRadius: 24, borderBottomRightRadius: 24, }}
      />
    </div>
  );
};
