import sass from "./Checkbox.module.scss";
import { FormikValues } from "formik";
import { AnchorHTMLAttributes, FC } from "react";
import { CheckboxActiveIcon, CheckboxInactiveIcon } from "../../icons";

type CheckboxProps = {
  formik: FormikValues,
  name: string,
  label: string,
  value: string,
  labelStyle?: React.HTMLAttributes<HTMLLabelElement>["style"],
  textStyle?: React.HTMLAttributes<HTMLLabelElement>["style"],
  href?: string,
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"],
  rel?: string,
}

export const Checkbox: FC<CheckboxProps> = ({
  formik,
  name,
  label,
  value,
  labelStyle,
  textStyle,
  href,
  target,
  rel,
}) => {
  const isChecked = formik.values[name].includes(value);

  const baseStylesLabel: React.HTMLAttributes<HTMLLabelElement>["style"] = {
    position: "relative",
    zIndex: 100,
    display: "flex",
    gap: 5,
    alignItems: "center",
  }

  return (
    <label
      className={sass.labelCheckbox}
      style={labelStyle ? { ...baseStylesLabel, ...labelStyle } : baseStylesLabel}
    >
      {
        isChecked
          ? <CheckboxActiveIcon />
          : <CheckboxInactiveIcon />
      }
      <input
        name={name}
        id={name}
        className={sass.checkbox}
        type="checkbox"
        checked={isChecked}
        value={value}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {
        href
          ? (
            <a href={href} target={target ? target : "_self"} rel={rel ? rel : "nofollow"}>
              <span style={textStyle ? textStyle : {}}>{label}</span>
            </a>
          ) : (
            <span style={textStyle ? textStyle : {}}>{label}</span>
          )
      }
    </label>
  )
}
