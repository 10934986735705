import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  checked: Yup
    .array()
    .of(Yup.string())
    .required("Поле обовʼязкове")
    .test("agree", "Поле обовʼязкове", (value) => {
      return new Promise(resolve => {
        resolve(value && value.includes("agree"));
      });
    }),
  photo: Yup
    .array()
    .min(2, "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)")
    .required("Поле обовʼязкове")
    .test("fileFormat", "Формат файла повинен бути jpg или png", (value) => {
      if (!value) return true;

      const validFormats = ["jpg", "png", "jpeg"];
      return value.every(file => {
        const fileName = file.name.toLowerCase();
        return validFormats.some(format => fileName.endsWith(`.${format}`));
      });
    }),
});
