import { CancelReason, CanceledFormInitialValues, InfoParamsData } from "../d";

export const getCanceledInfoData = ({
  reason,
  values,
  price
}: {
  reason: CancelReason,
  values: CanceledFormInitialValues,
  price: number,
}): InfoParamsData => {
  switch (reason) {
    case "insufficientAmount":
      return {
        documents: [],
        price: price,
        reason: `Недостатня сума - ${values.enterYourPrice}`,
        result: "no",
      }
    case "notReady":
      return {
        documents: [],
        price: price,
        reason: `На даний момент не готовий продавати - ${values.remind}`,
        result: "no",
      }
    case "other":
      return {
        documents: [],
        price: price,
        reason: `Вкажіть Ваш варіант - ${values.userReason}`,
        result: "no",
      }
    default:
      return {
        documents: [],
        price: 0,
        reason: "",
        result: "",
      }
  }
}
