import sass from "./SuccessInfo.module.scss";
import { FC } from "react";
import { useAppDispatch, useReduxState, useTranslation } from "../../../../../../hooks";
import { BackArrowIcon } from "../../../../../../icons";
import { setSwitchValue } from "../../../../../../redux";
import { RequestTitle } from "../index";
import { Button } from "../../../../../../components"

type SuccessInfoProps = {
  backValue: number,
  forwardValue: number,
  notNowValue: number,
}

export const SuccessInfo: FC<SuccessInfoProps> = ({ backValue, forwardValue, notNowValue }) => {
  const { buyToSell: { fetchedBuyInfo } } = useReduxState();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isNotSuccessFetchedInfo = fetchedBuyInfo === undefined;

  return (
    isNotSuccessFetchedInfo
      ? null
      : (
        <div className={sass.successBody}>
          <div className={sass.successWrapper}>
            <div className={sass.successBodyTop}>
              <button
                onClick={() => dispatch(setSwitchValue(backValue))}
                className={sass.backBtn}
                type="button"
              >
                <BackArrowIcon />
              </button>
              <div className={sass.title}>
                <RequestTitle title={t("buyToSellPage.requestForLand")} />
              </div>
            </div>
            <p className={sass.successText}>{t("buyToSellPage.readyToBuy")}</p>
            <p className={sass.successAmount}>
              <span>{fetchedBuyInfo.data.price2}</span>
              <span>{t("UAH")}</span>
              <span className={sass.perOneHa}>{t("buyToSellPage.perOneHa")}</span>
            </p>
            <p className={sass.successText}>{t("buyToSellPage.amountAfterTaxes")}</p>
            <p className={sass.successAmountAfterTaxes}>
              <span>{fetchedBuyInfo.data.price}</span>
              <span>{t("UAH")}</span>
              <span className={sass.perOneHa}>{t("buyToSellPage.perOneHa")}</span>
            </p>
            <div className={sass.buttons}>
              <Button
                text={t("notNow")}
                onClick={() => dispatch(setSwitchValue(notNowValue))}
                variant="secondary"
              />
              <Button
                text={t("wantToSell")}
                onClick={() => dispatch(setSwitchValue(forwardValue))}
                variant="accent"
              />
            </div>
          </div>
        </div>
      )
  )
}
