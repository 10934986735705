import sass from "./TakePhotoInput.module.scss";
import { ChangeEventHandler, FC, InputHTMLAttributes, useState } from "react";
import { PlusIcon } from "../../icons";
import { FormikValues } from "formik";

type TakePhotoInputProps = {
  label?: string,
  name: string,
  formik: FormikValues,
  onChange: ChangeEventHandler<HTMLInputElement>,
  accept: InputHTMLAttributes<HTMLInputElement>["accept"],
}

export const TakePhotoInput: FC<TakePhotoInputProps> = ({
  label,
  name,
  formik,
  onChange,
  accept,
}) => {
  const [errorShown, setErrorShown] = useState(false);

  const isError = formik.errors[name];

  return (
    <label className={sass.label}>
      <PlusIcon />
      <input
        className={sass.input}
        name={name}
        id={name}
        type="file"
        onChange={(e) => {
          onChange(e);
          setErrorShown(true);
        }}
        onBlur={formik.handleBlur}
        multiple
        accept={accept}
      />
      {
        label && <span>{label}</span>
      }
      {
        (isError && errorShown) && <span className={sass.errorText}>{formik.errors[name]}</span>
      }
    </label>
  )
}
