import sass from "./RequestTitle.module.scss";
import { FC } from "react";

type RequestTitleProps = {
  title: string,
}

export const RequestTitle: FC<RequestTitleProps> = ({ title }) => {
  return <p className={sass.title}>{title}</p>
}
