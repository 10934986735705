import * as Yup from 'yup';

const regexNums = /^[0-9]+$/;

export const validationSchema = (states: string[]) => {
  return Yup.object().shape({
    name: Yup
      .string()
      .min(4)
      .required("Поле обовʼязкове"),
    phone: Yup
      .string()
      .test('no-X', 'Введіт поле до кінця', (value) => {
        return new Promise(resolve => {
          resolve(!value || !value.includes('X'));
        });
      })
      .required("Поле обовʼязкове"),
    square: Yup
      .string()
      .matches(regexNums, "Поле повинно містити тільки цифри"),
    investment: Yup
      .string()
      .matches(regexNums, "Поле повинно містити тільки цифри"),
    amountGa: Yup
      .string()
      .matches(regexNums, "Поле повинно містити тільки цифри"),
    selectedState: Yup
      .string()
      .oneOf(states, "values is from states"),
    checked: Yup
      .array()
      .of(Yup.string())
      .test('isAgreePersonalData', 'Поле обовʼязкове', (value) => {
        return new Promise(resolve => {
          resolve(value! && value.includes("isAgreePersonalData"));
        });
      }),
  });
}
