import sass from "./FormBuy.module.scss";
import { FC, useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useReduxState, useTranslation } from "../../../../../../hooks";
import { Checkbox, SearchLocalities, TextInput } from "../../../../../../components";
import { validationSchema } from "./validationSchema";
import { SecurityPersonalDataBtn, SubmitButton } from "../index";
import { fetchBuyInfo, passBuyInfo, setSwitchValue } from "../../../../../../redux";

type InitialValuesFormBuy = {
  cn: string,
  name: string,
  phone: string,
  searchLocation: string,
  checked: string[],
}

export const FormBuy: FC = () => {
  const { t } = useTranslation();
  const { buyToSell } = useReduxState();
  const dispatch = useAppDispatch();

  const initialValues: InitialValuesFormBuy = {
    cn: "",
    name: "",
    phone: "",
    searchLocation: "",
    checked: ["isAgreePersonalData"],
  }

  const onSubmit = async ({ cn, name, phone }: InitialValuesFormBuy) => {
    const data = cn === ""
      ? {
        name,
        phone,
        address: {
          KOATUU: Number(buyToSell.selectKoatuu),
        }
      } : {
        cn,
        name,
        phone,
      };

    const passInfo = await dispatch(passBuyInfo(data));

    if (passInfo.type !== "pass-buy-info/fulfilled") return;

    const buyInfo = await dispatch(fetchBuyInfo({ mid: passInfo.payload.mid }));

    if (buyInfo.type === "fetch-sell-info/fulfilled" && data.cn === undefined) {
      dispatch(setSwitchValue(3));
    } else if (buyInfo.type === "fetch-sell-info/fulfilled" && data.cn !== "") {
      dispatch(setSwitchValue(2));
    }
  }

  const koatuus = buyToSell.koatuus.map(({ value }) => value);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(koatuus),
  });

  const isNotCnNumber = formik.values.checked.includes("isNotCnNumber");
  const isDisabled = (Object.values(formik.errors).length !== 0)
    || !formik.values.checked.includes("isAgreePersonalData")
    || (!isNotCnNumber && formik.values.cn === "");

  useEffect(() => {
    if (isNotCnNumber) {
      formik.setFieldValue("cn", "");
    }
    // eslint-disable-next-line
  }, [isNotCnNumber]);

  return (
    <form className={sass.form} onSubmit={formik.handleSubmit}>
      <div className={sass.formBody}>
        <TextInput
          mask="9999999999:99:999:9999"
          maskChar="X"
          formik={formik}
          label={t("buyToSellPage.cnNumber")}
          placeholder="XXXXXXXXXX:XX:XXX:XXXX"
          name="cn"
          disabled={isNotCnNumber}
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.firstMiddleName")}
          placeholder={t("buyToSellPage.enterYourName")}
          name="name"
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.phoneNumber")}
          placeholder="+380XXXXXXXXX"
          maskChar="X"
          mask="+3\80999999999"
          name="phone"
        />
        <div className={isNotCnNumber ? sass.coordinatesActive : sass.coordinates}>
          <SearchLocalities name="searchLocation" formik={formik} />
        </div>
        <Checkbox
          formik={formik}
          name="checked"
          value="isNotCnNumber"
          label={t("buyToSellPage.noCadastralNumber")}
          labelStyle={{
            fontSize: 21,
            fontWeight: 700,
            lineHeight: 28 / 21,
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 3,
            gridRowEnd: 4,
          }}
        />
        <div className={sass.submitBtnWrapper}>
          <SubmitButton
            disabled={isDisabled}
            text={t("buyToSellPage.siteEvaluate")}
            type="submit"
          />
        </div>
        <Checkbox
          labelStyle={{
            gridColumnStart: 3,
            gridColumnEnd: 4,
            gridRowStart: 3,
            gridRowEnd: 4,
          }}
          formik={formik}
          name="checked"
          value="isAgreePersonalData"
          label={t("buyToSellPage.agreePersonalData")}
          href="https://land-invest.com.ua/agreement_290621.pdf"
          target="_blank"
          rel="noreferrer"
        />
        <div className={sass.securityPersonalDataBtn}>
          <SecurityPersonalDataBtn />
        </div>
      </div>
    </form>
  );
};
