import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchedBuyInfo, Option, OptionWithId } from "../../d";
import {
  fetchBuyInfo,
  fetchKoatuu,
  fetchTypesOfDocument,
  passBuyInfo,
  passCanceledInfo,
  passSellInfo,
  passUserInfoForSell,
} from "./operations";

type BuyToSellState = {
  isLoadForm: boolean,
  isLoadKoatuus: boolean,
  error: unknown,
  buy: {
    mid: string,
  },
  fetchedBuyInfo: FetchedBuyInfo | undefined,
  sell: {},
  userInfoForSell: {},
  switchValue: number,
  koatuus: OptionWithId[],
  isOpenModal: boolean,
  selectKoatuu: string,
  typesOfDocument: Option[],
  notNowInfo: {},
}

const initialState: BuyToSellState = {
  isLoadForm: false,
  isLoadKoatuus: false,
  error: null,
  buy: {
    mid: "",
  },
  fetchedBuyInfo: undefined,
  sell: {},
  userInfoForSell: {},
  switchValue: 0,
  koatuus: [],
  isOpenModal: false,
  selectKoatuu: "",
  typesOfDocument: [],
  notNowInfo: {},
}

const buyToSellSlice = createSlice({
  name: "buy-to-sell",
  initialState,
  reducers: {
    setSwitchValue(state, action: PayloadAction<number>) {
      state.switchValue = action.payload;
    },
    setIsOpenModal(state, action: PayloadAction<boolean>) {
      state.isOpenModal = action.payload;
    },
    setKoatuu(state, action: PayloadAction<string>) {
      state.selectKoatuu = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(passBuyInfo.pending, (state) => {
        state.isLoadForm = true;
      })
      .addCase(passBuyInfo.fulfilled, (state, action) => {
        state.isLoadForm = false;
        state.error = null;
        state.buy = action.payload;
      })
      .addCase(passBuyInfo.rejected, (state, action) => {
        state.isLoadForm = false;
        state.error = action.payload;
      })
      .addCase(fetchBuyInfo.pending, (state) => {
        state.isLoadForm = true;
      })
      .addCase(fetchBuyInfo.fulfilled, (state, action) => {
        state.isLoadForm = false;
        state.error = null;
        state.fetchedBuyInfo = action.payload;
      })
      .addCase(fetchBuyInfo.rejected, (state, action) => {
        state.isLoadForm = false;
        state.error = action.payload;
      })
      .addCase(passSellInfo.pending, (state) => {
        state.isLoadForm = true;
      })
      .addCase(passSellInfo.fulfilled, (state, action) => {
        state.isLoadForm = false;
        state.error = null;
        state.sell = action.payload;
      })
      .addCase(passSellInfo.rejected, (state, action) => {
        state.isLoadForm = false;
        state.error = action.payload;
      })
      .addCase(fetchKoatuu.pending, (state) => {
        state.isLoadKoatuus = true;
      })
      .addCase(fetchKoatuu.fulfilled, (state, action) => {
        state.isLoadKoatuus = false;
        state.error = null;
        state.koatuus = action.payload;
      })
      .addCase(fetchKoatuu.rejected, (state, action) => {
        state.isLoadKoatuus = false;
        state.error = action.payload;
      })
      .addCase(fetchTypesOfDocument.pending, () => { })
      .addCase(fetchTypesOfDocument.fulfilled, (state, action) => {
        state.error = null;
        state.typesOfDocument = action.payload;
      })
      .addCase(fetchTypesOfDocument.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(passUserInfoForSell.pending, (state) => {
        state.isLoadForm = true;
      })
      .addCase(passUserInfoForSell.fulfilled, (state, action) => {
        state.isLoadForm = false;
        state.error = null;
        state.userInfoForSell = action.payload;
      })
      .addCase(passUserInfoForSell.rejected, (state, action) => {
        state.isLoadForm = false;
        state.error = action.payload;
      })
      .addCase(passCanceledInfo.pending, (state) => {
        state.isLoadForm = true;
      })
      .addCase(passCanceledInfo.fulfilled, (state, action) => {
        state.isLoadForm = false;
        state.error = null;
        state.notNowInfo = action.payload;
      })
      .addCase(passCanceledInfo.rejected, (state, action) => {
        state.isLoadForm = false;
        state.error = action.payload;
      })
  },
});

export const {
  setSwitchValue,
  setIsOpenModal,
  setKoatuu,
} = buyToSellSlice.actions;
export default buyToSellSlice.reducer;
