import sass from "./FormCanceled.module.scss";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { BackArrowIcon, CancaledIcon } from "../../../../../../icons";
import { useAppDispatch, useReduxState, useTranslation } from "../../../../../../hooks";
import { passCanceledInfo, setSwitchValue } from "../../../../../../redux";
import { getCanceledInfoData, getToday } from "../../../../../../utils";
import { Checkbox, Radio } from "../../../../../../components";
import { ReasonBody, SubmitButton, UserReasonTextArea } from "../index";
import { CanceledFormInitialValues } from "../../../../../../d";
import { validationSchema } from "./validationSchema";

type CanceledFormProps = {
  backValue: number,
  forwardValue: number,
}

export const CanceledForm: FC<CanceledFormProps> = ({ backValue, forwardValue }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { buyToSell } = useReduxState();

  const initialValues: CanceledFormInitialValues = {
    cancelReason: "",
    checked: ["agree"],
    enterYourPrice: "",
    remind: "",
    userReason: "",
  }

  const onSubmit = async (values: CanceledFormInitialValues) => {
    const data = getCanceledInfoData({
      reason: values.cancelReason,
      price: buyToSell.fetchedBuyInfo?.data.price2 || 0,
      values: values,
    });

    const passInfoRes = await dispatch(passCanceledInfo({ data, mid: buyToSell.buy.mid }));

    if (passInfoRes.type !== "pass-canceled-info/fulfilled") return;

    dispatch(setSwitchValue(forwardValue));
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log(formik.values);

  const isDisabled = Object.values(formik.errors).length !== 0;

  useEffect(() => {
    if (formik.values.cancelReason === "notReady") {
      formik.setFieldValue("remind", getToday());
    } else {
      formik.setFieldValue("remind", "");
    }
    // eslint-disable-next-line
  }, [formik.values.cancelReason]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={sass.formBody}>
        <div className={sass.formBodyTop}>
          <button
            onClick={() => dispatch(setSwitchValue(backValue))}
            type="button"
            className={sass.goBack}
          >
            <BackArrowIcon />
          </button>
          <p className={sass.title}>{t("buyToSellPage.reasonRefusal")}</p>
        </div>
        <div className={sass.imageRadioWrapper}>
          <div className={sass.formBodyImg}>
            <CancaledIcon />
          </div>
          <div className={sass.radiosGroup}>
            <Radio
              value="insufficientAmount"
              name="cancelReason"
              label={t("buyToSellPage.insufficientAmount")}
              formik={formik}
              component={<ReasonBody
                mask="999999999999999999999999999999999999999999999"
                text={t("buyToSellPage.yourPrice")}
                name="enterYourPrice"
                formik={formik}
                placeholder={t("buyToSellPage.howMuch")}
              />}
            />
            <Radio
              value="notReady"
              name="cancelReason"
              label={t("buyToSellPage.notReady")}
              formik={formik}
              component={<ReasonBody
                text={t("buyToSellPage.remind")}
                name="remind"
                formik={formik}
                type="date"
                min={getToday()}
              />}
            />
            <Radio
              value="other"
              name="cancelReason"
              label={t("buyToSellPage.other")}
              formik={formik}
              component={<UserReasonTextArea
                name="userReason"
                formik={formik}
                placeholder={t("buyToSellPage.specifyYourOption")}
              />}
            />
          </div>
        </div>
        <div className={sass.formBodyBottomWrapper}>
          <div className={sass.formBodyBottom}>
            <SubmitButton
              text={t("send")}
              type="submit"
              disabled={isDisabled}
            />
            <Checkbox
              formik={formik}
              name="checked"
              value="agree"
              label={t("buyToSellPage.agree")}
            />
          </div>
        </div>
      </div>
    </form>
  )
}
