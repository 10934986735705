import sass from "./Description.module.scss";
import { FC } from "react";

type DescriptionProps = {
  text: string,
}

export const Description: FC<DescriptionProps> = ({ text }) => {
  return (
    <p className={sass.description}>{text}</p>
  )
}
