import sass from "./ChooseAuthToSell.module.scss";
import { FC } from "react";
import { setSwitchValue } from "../../../../../../redux";
import { useAppDispatch, useTranslation } from "../../../../../../hooks";
import { BackArrowIcon, KeyIcon } from "../../../../../../icons";
import { Description, RequestTitle } from "../index";
import { Button } from "../../../../../../components";

type ChooseAuthToBuyProps = {
  backValue: number,
  forwardValue: number,
}

export const ChooseAuthToBuy: FC<ChooseAuthToBuyProps> = ({ backValue, forwardValue }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className={sass.chooseAuthToSell}>
      <div className={sass.chooseAuthToSellTop}>
        <button
          type="button"
          onClick={() => dispatch(setSwitchValue(backValue))}
        >
          <BackArrowIcon />
        </button>
        <RequestTitle title={t("buyToSellPage.requestForLand")} />
      </div>
      <div className={sass.description}>
        <Description text={t("buyToSellPage.completeEvaluation")} />
      </div>
      <div className={sass.buttons}>
        <Button
          text="BankID/Mobile ID/КЕП"
          variant="accent"
          icon={<KeyIcon />}
          href="https://id.gov.ua/?response_type=code&client_id=f2165151da492a5a82a899dfd9b2dae3&scope=&response_type=code&redirect_uri=https://land-invest.com.ua/success&state=CfDJ8MxrCNIJ7D1Hm8Q86uOI_kJOqxe-t5CMgDwXzwxBnpZx7QLPpQZFJ94DPaW0PD99PxsKwVrzET4AbL8BvuNdB5F04AXjRTvgtHGNI8bpmKx96lewwXJkYPF39TeFc6j8FpbvJtzjGN8Y7geGpVsnusc8W790ljWvgZkCL6-x8dZ3cUTPc1KoyTlaY1jIVP33je8e6gSXEp5WpaF8kAepZbGottwPEaxpB6wOq9-M5sd3PhF2Fq6YOWIPQqx1Vv9Z6SS-Wv2IXisjPllimvw94v8"
        />
        <Button
          text={t("buyToSellPage.dataManually")}
          variant="secondary"
          onClick={() => dispatch(setSwitchValue(forwardValue))}
        />
      </div>
    </div>
  )
}
