import sass from "./ModalPersonalData.module.scss";
import { FC } from "react";
import { useAppDispatch, useReduxState, useTranslation } from "../../../../../../hooks";
import { setIsOpenModal } from "../../../../../../redux";
import { SecurityPersonalDataIcon } from "../../../../../../icons";

export const ModalPersonalData: FC = () => {
  const dispatch = useAppDispatch();
  const { buyToSell } = useReduxState();
  const { t } = useTranslation();

  return (
    <>
      <div onClick={() => dispatch(setIsOpenModal(false))} className={sass.backdrop}></div>
      <div className={buyToSell.isOpenModal ? sass.modalBodyActive : sass.modalBody}>
        <div className={sass.modalTitleBlock}>
          <SecurityPersonalDataIcon width={68} height={80} />
          <div className={sass.modalTitle}>{t("buyToSellPage.applicationPrivacyPolicy")}</div>
        </div>
        <div className={sass.modalTextBlock}>
          <p className={sass.modalText}>{t("buyToSellPage.privacyPolicyText1")}</p>
          <p className={sass.modalText}>{t("buyToSellPage.privacyPolicyText2")}</p>
          <p className={sass.modalText}>{t("buyToSellPage.privacyPolicyText3")}</p>
          <p className={sass.modalText}>
            <a
              className={sass.modalLink}
              target="_blank"
              href="https://land-invest.com.ua/privacy-policy"
              rel="noreferrer"
            >
              {t("buyToSellPage.privacyPolicy")}
            </a>
          </p>
        </div>
        <div className={sass.modalClose}>
          <button
            onClick={() => dispatch(setIsOpenModal(false))}
            className={sass.modalCloseBtn}
            type="button"
          >
            {t("ok")}
          </button>
        </div>
      </div>
    </>
  )
};
