import { useEffect, useState } from "react";

export const useSwitch = () => {
  const [isStart, setIsStart] = useState(false);

  useEffect(() => {
    setIsStart(true);

    return () => {
      setIsStart(false);
    }
  }, []);

  return isStart;
}
