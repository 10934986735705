import { FC, useState } from "react";
import Select from 'react-select';
import { Option } from "../../d";
import { FormikValues } from "formik";

type SelectLocalitiesProps = {
  text?: string,
  options: Option[],
  name: string,
  placeholder: string,
  formik: FormikValues,
}

export const SelectLocalities: FC<SelectLocalitiesProps> = ({
  text,
  options,
  name,
  formik,
  placeholder,
}) => {
  const [isDropdownFocused, setDropdownFocused] = useState(false);

  return (
    <div>
      {
        text && <span style={{ marginBottom: 5, display: "block" }}>{text}</span>
      }
      <Select
        onMenuOpen={() => setDropdownFocused(true)}
        onMenuClose={() => setDropdownFocused(false)}
        // placeholder={t("buyToSellPage.selectArea")}
        placeholder={placeholder}
        onInputChange={(value) => {
          if (value.length <= 2) return;
        }}
        name={name}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: "2px 4px",
            border: "1px solid #7e96ae",
            borderRadius: 8,
            maxHeight: 36,
            minHeight: 36,
            height: 36,
            alignItems: "flex-start",
            cursor: "pointer",
            backgroundColor: "transparent",
            width: "100%",
            boxShadow: "none",
            "&:hover": {
              borderColor: "#3f4e5d",
            },
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            color: "#3f4e5d",
            paddingBottom: 0,
            paddingTop: 0,
            position: "relative",
            transform: isDropdownFocused ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 400ms cubic-bezier(.445,.05,.55,.95)",
            marginRight: 10,

            "&:before": {
              content: '""',
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 0,
              height: 0,
              borderLeft: "7px solid transparent",
              borderRight: "7px solid transparent",
              borderTop: "7px solid #3f4e5d",
            },
            "& svg": {
              display: "none"
            }
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: "#3f4e5d",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#fff",
            zIndex: 150,
            width: "100%",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: state.isSelected ? "#fff" : "#333",
            backgroundColor: state.isSelected ? "#cecece9c" : "transparent",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#3f4e5d",
              color: "#fff",
            },
          }),
        }}
        onChange={(selectedValue) => {
          setDropdownFocused(false);
          formik.setFieldValue(name, selectedValue!.value);
        }}
        options={options}
      />
    </div>
  )
}
