import sass from "./SecurityPersonalDataBtn.module.scss";
import { FC } from "react";
import { useAppDispatch, useTranslation } from "../../../../../../hooks";
import { setIsOpenModal } from "../../../../../../redux";
import { SecurityPersonalDataIcon } from "../../../../../../icons";

export const SecurityPersonalDataBtn: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={sass.securityPersonalDataBtn}
      onClick={() => dispatch(setIsOpenModal(true))}
    >
      <SecurityPersonalDataIcon />
      <span>{t("buyToSellPage.securetyPersonalData")}</span>
    </button>
  )
}
