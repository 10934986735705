import sass from "./BuyToSellPage.module.scss";
import { FC, ReactNode } from "react";
import { useReduxState, useTranslation } from "../../../../hooks";
import {
    ContentWrapper,
    FormBuy,
    FormSell,
    FormsSwitcher,
    ModalPersonalData,
    CanceledForm,
    ChooseAuthToBuy,
    FormManuallyData,
    SuccessInfo,
    Recall,
    ApplicationAccepted,
    FormLastStep,
    RequestSuccessScreen,
} from "./components";
import {
    Container,
    Title3dOrder,
    LoaderScreen,
} from "../../../../components";

export const BuyToSellSection: FC = () => {
    const { buyToSell } = useReduxState();
    const { t } = useTranslation();

    console.log("Current index screen", buyToSell.switchValue);

    const forms: ReactNode[] = [
        <ContentWrapper component={<FormBuy />} />,
        <ContentWrapper component={<FormSell />} />,
        <ContentWrapper component={<Recall backValue={0} />} />,
        <ContentWrapper component={<SuccessInfo backValue={0} notNowValue={6} forwardValue={5} />} />,
        <ContentWrapper component={<ApplicationAccepted backValue={1} />} />,
        <ContentWrapper component={<ChooseAuthToBuy backValue={3} forwardValue={7} />} />,
        <ContentWrapper component={<CanceledForm backValue={3} forwardValue={9} />} />,
        <ContentWrapper component={<FormManuallyData backValue={5} forwardValue={8} />} />,
        <ContentWrapper component={<FormLastStep backValue={7} forwardValue={9} />} />,
        <ContentWrapper component={<RequestSuccessScreen />} />,
    ];

    return (
        <section className={sass.buyToSellSection}>
            {
                buyToSell.isLoadForm && <LoaderScreen />
            }
            <Container>
                <div className={sass.buyToSellTitle}>
                    <Title3dOrder text={t("buyToSellPage.title")} />
                </div>
                <div className={sass.switchWrapper}>
                    <FormsSwitcher />
                </div>
                {forms[buyToSell.switchValue]}
            </Container>
            <div className={buyToSell.isOpenModal ? sass.modalWrapperActive : sass.modalWrapper}>
                <ModalPersonalData />
            </div>
        </section>
    );
}
