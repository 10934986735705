import sass from "./Radio.module.scss";
import { FormikValues } from "formik";
import { FC, ReactNode } from "react";

type RadioProps = {
  name: string,
  label?: string,
  formik: FormikValues,
  value: string,
  component: ReactNode,
}

export const Radio: FC<RadioProps> = ({
  name,
  label,
  formik,
  value,
  component: Component,
}) => {

  const isChecked = formik.values.cancelReason === value;

  return (
    <div className={sass.wrapper}>
      <label className={sass.label}>
        <input
          className={sass.input}
          value={value}
          name={name}
          type="radio"
          onChange={(event) => {
            formik.handleChange(event);
            formik.setFieldValue(name, event.target.value);
          }}
          onBlur={formik.handleBlur}
          checked={isChecked}
        />
        {
          label && <span>{label}</span>
        }
      </label>
      {
        isChecked && Component
      }
    </div>
  )
}
