import sass from "./ApplicationAccepted.module.scss";
import { FC } from "react";
import { RequestTitle } from "../index";
import { useAppDispatch, useTranslation } from "../../../../../../hooks";
import { Button } from "../../../../../../components";
import { setSwitchValue } from "../../../../../../redux";

type ApplicationAcceptedProps = {
  backValue: number,
}

export const ApplicationAccepted: FC<ApplicationAcceptedProps> = ({ backValue }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={sass.wrapper}>
      <div className={sass.title}>
        <RequestTitle title={t("buyToSellPage.thankUsingOurService")} />
      </div>
      <p className={sass.text}>{t("buyToSellPage.applicationAccepted")}</p>
      <Button
        onClick={() => dispatch(setSwitchValue(backValue))}
        text={t("ok")}
        variant="accent"
      />
    </div>
  )
}
