import sass from "./SubmitButton.module.scss";
import React, { FC } from "react";
import { useDisabled } from "../../../../../../hooks";

type SubmitButtonProps = {
  type: React.ButtonHTMLAttributes<HTMLButtonElement>["type"],
  text: string,
  disabled: boolean,
}

export const SubmitButton: FC<SubmitButtonProps> = ({ type = "button", text, disabled }) => {
  const refBtn = useDisabled();

  return (
    <button
      disabled={disabled}
      className={sass.submitBtn}
      type={type}
      ref={refBtn}
    >
      {text}
    </button>
  );
};
