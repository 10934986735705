import { FC } from 'react';
import { MainPage } from "./pages";
import { ContainerNotifications, Header } from './components';

const App: FC = () => {
  return (
    <>
      <Header />
      <MainPage />
      <ContainerNotifications />
    </>
  );
}

export default App;
