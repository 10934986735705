import sass from "./Title3dOrder.module.scss";
import { FC } from "react";

type TitleProps = {
  text: string,
}

export const Title3dOrder: FC<TitleProps> = ({ text }) => {
  return (
    <h3 className={sass.title}>{text}</h3>
  )
};
