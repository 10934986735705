import sass from "./FormManuallyData.module.scss";
import { ChangeEvent, FC } from "react";
import { useAppDispatch, usePassportPhoto, useTranslation } from "../../../../../../hooks";
import { setSwitchValue } from "../../../../../../redux";
import { Description, PhotoUser, RequestTitle, SubmitButton } from "../index";
import { Button, Checkbox, TakePhotoInput } from "../../../../../../components";
import { BackArrowIcon, KeyIcon, PassportBookIcon, PassportIdCardIcon } from "../../../../../../icons";
import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";
import { InitialValuesFormManuallyData } from "../../../../../../d";

type FormManuallyDataProps = {
  backValue: number,
  forwardValue: number,
}

export const FormManuallyData: FC<FormManuallyDataProps> = ({ backValue, forwardValue }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setPassport } = usePassportPhoto();

  const initialValues: InitialValuesFormManuallyData = {
    photo: [],
    checked: ["agree"],
  }

  const onSubmit = ({ photo }: InitialValuesFormManuallyData) => {
    setPassport(photo);
    dispatch(setSwitchValue(forwardValue));
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const isDisabled = Object.values(formik.errors).length !== 0;

  const handleChangePhoto = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const files = target.files;

    if (files && files.length > 0) {
      formik.setFieldValue("photo", Array.from(files));
    }
  }

  const removePhoto = (index: number) => {
    const updatedPhotos = [...formik.values.photo];
    updatedPhotos.splice(index, 1);
    formik.setFieldValue("photo", updatedPhotos);
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={sass.chooseAuthToSell}>
      <div className={sass.chooseAuthToSellTop}>
        <button
          type="button"
          onClick={() => dispatch(setSwitchValue(backValue))}
        >
          <BackArrowIcon />
        </button>
        <RequestTitle title={t("buyToSellPage.requestForLand")} />
      </div>
      <div className={sass.description}>
        <Description text={t("buyToSellPage.confirmPerson")} />
      </div>
      <div className={sass.buttons}>
        <Button
          text="BankID/Mobile ID/КЕП"
          variant="accent"
          icon={<KeyIcon />}
          href="https://id.gov.ua/?response_type=code&client_id=f2165151da492a5a82a899dfd9b2dae3&scope=&response_type=code&redirect_uri=https://land-invest.com.ua/success&state=CfDJ8MxrCNIJ7D1Hm8Q86uOI_kJOqxe-t5CMgDwXzwxBnpZx7QLPpQZFJ94DPaW0PD99PxsKwVrzET4AbL8BvuNdB5F04AXjRTvgtHGNI8bpmKx96lewwXJkYPF39TeFc6j8FpbvJtzjGN8Y7geGpVsnusc8W790ljWvgZkCL6-x8dZ3cUTPc1KoyTlaY1jIVP33je8e6gSXEp5WpaF8kAepZbGottwPEaxpB6wOq9-M5sd3PhF2Fq6YOWIPQqx1Vv9Z6SS-Wv2IXisjPllimvw94v8"
        />
        <Button
          text={t("buyToSellPage.dataManually")}
          variant="secondary"
        />
      </div>
      <div className={sass.content}>
        <div className={sass.photo}>
          <TakePhotoInput
            name="photo"
            onChange={handleChangePhoto}
            label={t("buyToSellPage.uploadPhotosDocs")}
            formik={formik}
            accept=".jpg, .jpeg, .png"
          />
          {
            formik.values.photo.length > 0 && (
              <ul className={sass.photoList}>
                {
                  formik.values.photo.map((photo, index) => (
                    <li className={sass.photoItem} key={index}>
                      <PhotoUser onClick={() => removePhoto(index)} photo={photo} />
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
        <div className={sass.images}>
          <PassportIdCardIcon />
          <p className={sass.or}>{t("or")}</p>
          <PassportBookIcon />
        </div>
        <div className={sass.interaction}>
          <SubmitButton
            text={t("continue")}
            type="submit"
            disabled={isDisabled}
          />
          <Checkbox
            formik={formik}
            name="checked"
            value="agree"
            label={t("buyToSellPage.agreePersonalData")}
            labelStyle={{
              color: "#7e96ae",
            }}
          />
        </div>
      </div>
    </form>
  )
}
