import sass from "./Header.module.scss";
import { FC, useState } from "react";
import { AppLanguages, appLanguages } from "../../d";
import { Container } from "../Container";
import { LogoIcon } from "../../icons";
import { useScroll, useTranslation } from "../../hooks";

export const Header: FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<AppLanguages>(i18n.language as AppLanguages);
  const isScrolled = useScroll();

  /// TODO: How to define the current language
  // console.log("i18n.language", i18n.language);

  const changeLanguage = (language: AppLanguages) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  }

  return (
    <header className={isScrolled ? sass.headerScroll : sass.header}>
      <Container>
        <div className={sass.headerInner}>
          <div className={sass.logo}>
            <a
              href="/"
            >
              <LogoIcon />
            </a>
          </div>
          <div className={sass.languageBtns}>
            <button
              className={selectedLanguage === appLanguages.en ? sass.selectLangeActive : sass.selectLange}
              type="button"
              onClick={() => changeLanguage(appLanguages.en)}
            >
              EN
            </button>
            <button
              className={selectedLanguage === appLanguages.ua ? sass.selectLangeActive : sass.selectLange}
              type="button"
              onClick={() => changeLanguage(appLanguages.ua)}
            >
              UA
            </button>
          </div>
        </div>
      </Container>
    </header>
  )
}
