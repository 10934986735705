import sass from "./ReasonBody.module.scss";
import { FC, InputHTMLAttributes } from "react";
import { FormikValues } from "formik";
import { useSwitch } from "../../../../../../hooks";
import { TextInput } from "../../../../../../components";

type ReasonBodyProps = {
  text: string,
  name: string,
  formik: FormikValues,
  type?: InputHTMLAttributes<HTMLInputElement>["type"],
  mask?: string,
  maskChar?: string,
  placeholder?: string,
  min?: string,
}

export const ReasonBody: FC<ReasonBodyProps> = ({
  text,
  name,
  type,
  formik,
  mask,
  maskChar,
  placeholder,
  min,
}) => {
  const isStart = useSwitch();

  return (
    <div className={isStart ? sass.bodyActive : sass.body}>
      <p className={sass.text}>{text}</p>
      <TextInput
        labelStyles={{ gap: 0, width: "100%", whiteSpace: "nowrap" }}
        mask={mask}
        maskChar={maskChar}
        placeholder={placeholder}
        name={name}
        type={type}
        formik={formik}
        min={min}
      />
    </div>
  )
}
