import sass from "./FormSell.module.scss";
import { FC } from "react";
import { useFormik } from "formik";
import { useAppDispatch, useTranslation } from "../../../../../../hooks";
import { Checkbox, SelectLocalities, TextInput } from "../../../../../../components";
import { SecurityPersonalDataBtn, SubmitButton } from "../index";
import { validationSchema } from "./validationSchema";
import locations from "../../../../../../data/locations.json";
import { passSellInfo, setSwitchValue } from "../../../../../../redux";

type InitialValuesFormSell = {
  name: string,
  phone: string,
  square: string,
  investment: string,
  amountGa: string,
  selectedState: string,
  checked: string[],
}

export const FormSell: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = locations.map(({ title }) => ({ value: title.split(" ")[0], label: title.split(" ")[0] }));

  const initialValues: InitialValuesFormSell = {
    name: "",
    phone: "",
    square: "",
    investment: "",
    amountGa: "",
    selectedState: "",
    checked: ["isAgreePersonalData"],
  }

  const onSubmit = async (values: InitialValuesFormSell) => {
    const data = {
      body: `<h1>Вітаємо на порталі!</h1> <br>Ім’я та по батькові: ${values.name}\nНомер телефону: ${values.phone}\nБажана площа, га: ${values.square}\nРозмір інвестицій, $: ${values.investment}}\nВартість за га, $: ${values.amountGa}\nБажаний регіон: ${values.selectedState}`,
      subject: "Повідомлення",
      to: ["support@frontmen.fm"],
    }

    const res = await dispatch(passSellInfo(data));

    if (res.type === "pass-sell-info/fulfilled") {
      dispatch(setSwitchValue(4));
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationSchema(options.map(({ value }) => value)),
  });

  const isDisabled = (Object.values(formik.errors).length !== 0) || !formik.values.checked.includes("isAgreePersonalData");

  const maskNumbers = "99999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999";

  return (
    <form className={sass.form} onSubmit={formik.handleSubmit}>
      <div className={sass.formBody}>
        <TextInput
          formik={formik}
          label={t("buyToSellPage.firstMiddleName")}
          placeholder={t("buyToSellPage.enterYourName")}
          name="name"
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.phoneNumber")}
          placeholder="+380XXXXXXXXX"
          maskChar="X"
          mask="+3\80999999999"
          name="phone"
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.desiredArea")}
          placeholder={t("buyToSellPage.enterNumber")}
          mask={maskNumbers}
          name="square"
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.investmentAmount")}
          placeholder={t("buyToSellPage.enterAmount")}
          mask={maskNumbers}
          name="investment"
        />

        <TextInput
          formik={formik}
          label={t("buyToSellPage.hectareCost")}
          placeholder={t("buyToSellPage.enterAmount")}
          mask={maskNumbers}
          name="amountGa"
        />

        <SelectLocalities
          name="selectedState"
          options={options}
          text={t("buyToSellPage.preferredRegion")}
          placeholder={t("buyToSellPage.selectArea")}
          formik={formik}
        />

        <div className={sass.submitBtnWrapper}>
          <SubmitButton text={t("buyToSellPage.siteChoose")} type="submit" disabled={isDisabled} />
        </div>
        <div className={sass.agreePersonalData}>
          <Checkbox
            labelStyle={{ alignSelf: "flex-start" }}
            formik={formik}
            name="checked"
            value="isAgreePersonalData"
            label={t("buyToSellPage.agreePersonalData")}
            href="https://land-invest.com.ua/agreement_290621.pdf"
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <div className={sass.securityPersonalDataBtn}>
          <SecurityPersonalDataBtn />
        </div>
      </div>
    </form>
  )
};
