import sass from "./SwitcherButton.module.scss";
import { FC } from "react";
import { useAppDispatch, useReduxState } from "../../../../../../hooks";
import { setSwitchValue } from "../../../../../../redux";

type SwitcherButtonProps = {
  text: string,
  switchValue: number,
  style?: React.HTMLAttributes<HTMLButtonElement>["style"],
  activeValues: number[],
}

export const SwitcherButton: FC<SwitcherButtonProps> = ({
  text,
  switchValue,
  style,
  activeValues,
}) => {
  const dispatch = useAppDispatch();

  const { buyToSell } = useReduxState();

  const isActiveBtn = activeValues.includes(buyToSell.switchValue);

  return (
    <button
      type="button"
      className={isActiveBtn ? sass.switcherBtnActive : sass.switcherBtn}
      style={style ? style : {}}
      onClick={() => dispatch(setSwitchValue(switchValue))}
    >
      {text}
    </button>
  );
};
