import sass from "./PhotoUser.module.scss";
import { FC, MouseEventHandler } from "react";
import { IoClose } from "react-icons/io5";

type PhotoUserProps = {
  photo: File,
  onClick: MouseEventHandler<HTMLButtonElement>,
}

export const PhotoUser: FC<PhotoUserProps> = ({ photo, onClick }) => {
  return (
    <div className={sass.wrapper}>
      <button
        onClick={onClick}
        className={sass.removePhotoBtn}
        type="button"
      >
        <IoClose color="#b52828" size={25} />
      </button>
      <img className={sass.photo} src={URL.createObjectURL(photo)} alt="" />
    </div>
  )
}
