import sass from "./ContentWrapper.module.scss";
import { FC, ReactNode } from "react";

type ContentWrapperProps = {
  component: ReactNode,
}

export const ContentWrapper: FC<ContentWrapperProps> = ({ component: Component }) => {
  return (
    <div className={sass.wrapper}>{Component}</div>
  )
}
