import sass from "./SearchLocalities.module.scss";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { fetchKoatuu, setKoatuu } from "../../redux";
import { useAppDispatch, useReduxState, useTranslation } from "../../hooks";
import { OptionWithId } from "../../d";
import { FormikValues } from "formik";

type SearchLocalitiesProps = {
  name: string,
  formik: FormikValues,
}

export const SearchLocalities: FC<SearchLocalitiesProps> = ({ name, formik }) => {
  const dispatch = useAppDispatch();
  const { buyToSell } = useReduxState();
  const [searchValue, setSearchValue] = useState("");
  const [isFoucus, setIsFoucus] = useState(false);
  const [searchList, setSearchList] = useState<OptionWithId[]>([]);
  const { t } = useTranslation();

  const isError = formik.errors[name] && formik.touched[name];

  useEffect(() => {
    dispatch(fetchKoatuu());
  }, [dispatch]);

  useEffect(() => {
    if (searchValue.length <= 1) return;

    const filteredLocalities = buyToSell.koatuus.filter(({ value }) => value.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
    setSearchList(filteredLocalities);
  }, [searchValue, buyToSell]);

  const handleClickOnLocality = ({ value, secondLevel }: { value: string, secondLevel: string }) => {
    setSearchValue(value);
    formik.setFieldValue(name, value);
    dispatch(setKoatuu(secondLevel));
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    formik.handleChange(event);
  }

  return (
    <div className={sass.searchWrapper}>
      <label className={sass.label}>
        <span>{t("buyToSellPage.siteLocation")}</span>
        <input
          name={name}
          placeholder={t("buyToSellPage.settlement")}
          className={isError ? sass.inputError : sass.input}
          value={searchValue}
          onFocus={() => setIsFoucus(true)}
          onBlur={(event) => {
            formik.handleBlur(event);
            setTimeout(() => setIsFoucus(false), 200)
          }}
          onChange={handleChange}
          type="text"
        />
      </label>
      {
        (searchValue.length > 0 && isFoucus && searchList.length > 0) && (
          <div className={sass.dropdown}>
            <ul className={sass.list}>
              {
                searchList.map(({ value, id, secondLevel }) => (
                  <li className={sass.item} key={id}>
                    <button
                      onClick={() => handleClickOnLocality({ value, secondLevel: secondLevel! })}
                      className={sass.itemBtn}
                      type="button"
                    >
                      {value}
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
    </div>
  );
}
